import {Admin, EditGuesser, fetchUtils, ListGuesser, Resource} from 'react-admin';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import turkishMessages from 'ra-language-turkish';
import authProvider from "./authProvider/authProvider";
import springHateosDataProvider from "./dataProvider/springHateosDataProvider";


const i18nProvider = polyglotI18nProvider(() => turkishMessages, 'tr');

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const token = JSON.parse(localStorage.getItem('auth'));
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

function App() {
    return (
        <Admin i18nProvider={i18nProvider}
               authProvider={authProvider}
               dataProvider={springHateosDataProvider('/api/supportdeck', httpClient)}>
            <Resource options={{label: 'Sınıf'}} name="classrooms" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Kurum'}} name="institutes" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Kurum Ölçümü'}} name="instituteMeasures" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Öğrenci Ölçümü'}} name="studentMeasures" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Ölçüm Analizleri'}} name="measureAnalyses" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Öğrenci Analiz Değendirmeleri)'}} name="studentAnalyses" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Lisans'}} name="licences" list={ListGuesser} edit={EditGuesser}
                      create={EditGuesser}/>
            <Resource options={{label: 'Sezon'}} name="seasons" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Öğrenci'}} name="students" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Öğretmen'}} name="teachers" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Eğitmen'}} name="trainers" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Lookup'}} name="lookups" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Sınıf Seviye'}} name="classLevels" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Sınıf Seviye Segment'}} name="classLevelSegments" list={ListGuesser} edit={EditGuesser}/>
            <Resource options={{label: 'Uyarılar'}} name="notifications" list={ListGuesser} edit={EditGuesser}/>
        </Admin>
    );
}

export default App;
